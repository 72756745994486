import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    PinInput,
    PinInputField,
    Flex,
} from "@chakra-ui/react";
import { Controller, Control, ErrorOption } from "react-hook-form";

interface FormPinInputProps {
    id: string;
    control?: Control;
    error: ErrorOption;
    label?: string;
    placeholder: string;
    name: string;
    isRequired?: boolean;
    type: string;
}

export default function FormPinInput({
    isRequired,
    error,
    label,
    name,
    control,
}: FormPinInputProps) {
    return (
        <FormControl isInvalid={!!error} pb="4" isRequired={isRequired}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref, ...rest } }) => (
                    <Flex justify="space-between" gap="3">
                        <PinInput
                            placeholder=""
                            variant="filled"
                            otp
                            size="lg"
                            {...rest}
                        >
                            <PinInputField ref={ref} />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                        </PinInput>
                    </Flex>
                )}
            />

            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
}
