import { Alert, AlertDescription, Box } from "@chakra-ui/react";

const AlertValidationErrors = ({ errors = [], alertMessage, ...props }) => (
    <>
        {errors.length > 0 && (
            <Box {...props}>
                <Alert
                    status="error"
                    bg={props.backgroundColor}
                    fontWeight="semibold"
                    border="0.2rem solid"
                    borderRadius="md"
                    borderColor={props.borderColor}
                    width={props.alertWidth}
                >
                    <AlertDescription pl={8}>{alertMessage}</AlertDescription>
                </Alert>
            </Box>
        )}
    </>
);

export default AlertValidationErrors;
