import Image, { ImageProps, StaticImageData } from "next/image";
import { useEffect, useState } from "react";
import fallbackImage from "../../public/login-assets/atrinium-logo.png";

interface ImageWithFallbackProps extends ImageProps {
    fallback?: StaticImageData | string;
}

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#e2e8f0" offset="10%" />
      <stop stop-color="#e2e8f1" offset="40%" />
      <stop stop-color="#e2e8f0" offset="60%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#e2e8f0" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
    typeof window === "undefined"
        ? Buffer.from(str).toString("base64")
        : window.btoa(str);

const ImageWithFallback = ({
    fallback = fallbackImage,
    alt,
    src,
    ...props
}: ImageWithFallbackProps) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null);
    }, [src]);

    return (
        <Image
            alt={alt}
            onError={setError}
            src={error ? fallback : src}
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
                shimmer(700, 475)
            )}`}
            {...props}
        />
    );
};

export default ImageWithFallback;
